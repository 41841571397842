<template>
  <div class="login">
    <div class="login-comp">
      <div class="login-comp-center comp-m">
        <div class="spin-nested-loading">
          <div class="spin-login">
            <div class="game-list">
              <div
                class="loginForm"
                :style="
                  State == 1
                    ? 'width: 800px;min-width: 800px;'
                    : 'width: 1200px;min-width: 1200px;'
                "
              >
                <div class="title">
                  <span
                    @click="ChangeState(1)"
                    :style="
                      State == 1
                        ? 'color:rgb(43,103,238);font-weight: 600;font-size:28px'
                        : 'font-size:24px;color:#ccc'
                    "
                    >登录</span
                  >
                  <span style="color: #ccc">&nbsp;&nbsp;/&nbsp;&nbsp;</span>
                  <span
                    @click="ChangeState(2)"
                    :style="
                      State == 2
                        ? 'color:rgb(43,103,238);font-weight: 600;font-size:28px'
                        : 'font-size:24px;color:#ccc'
                    "
                    >注册</span
                  >
                </div>
                <div v-show="State == 1">
                  <!-- :rules="LoginRules" -->
                  <el-form
                    ref="loginform"
                    label-position="left"
                    style="width: 60%; margin: 0 auto"
                    :model="loginForm"
                    label-width="70px"
                  >
                    <el-form-item label="手机号" prop="username">
                      <el-input
                        @keyup.enter.native="ClickLogin"
                        v-model="loginForm.username"
                      ></el-input>
                    </el-form-item>
                    <el-form-item label="密码" prop="password">
                      <el-input
                        show-password
                        @keyup.enter.native="ClickLogin"
                        v-model="loginForm.password"
                      ></el-input>
                    </el-form-item>
					<el-row>
						<el-col style="text-align: right;font-size: 14px;color: #ccc;" :span="24">
							<span style="color: rgb(0, 100, 200)" @click="ForgetPasswords">忘记密码</span>
						</el-col>
					</el-row>
                  </el-form>
				  
                  <el-row style="width: 200px; margin: 0px auto">
                    <el-col
                      :span="24"
                      style="text-align: center; margin-top: 20px"
                    >
                      <el-button
                        v-loading="loading"
                        style="width: 100%"
                        @click="ClickLogin"
                        type="success"
                        >登&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;录</el-button
                      >
                    </el-col>
                  </el-row>
                  <el-row
                    style="
                      text-align: center;
                      margin-top: 10px;
                      font-size: 14px;
                      color: #606266;
                    "
                  >
                    <el-col>
                      登录并使用比赛网站需遵守<span
					  @click="JumpUserAgreement"
                        style="color: rgb(0, 100, 200)"
                        >《用户注册协议》</span
                      >
                    </el-col>
                  </el-row>
                </div>
                <div v-show="State == 2">
                  <el-form
                    ref="RegisterForm"
                    style="width: 96%; margin: 0 auto"
                    :rules="RegisterRules"
                    :model="RegisterForm"
                    label-width="100px"
                    @keyup.enter.native="ClickRegister"
                  >
                    <el-row>
                      <el-col :span="12">
                        <el-form-item label="手机号" prop="username">
                          <el-input
                            v-model="RegisterForm.username"
                            placeholder="请输入真实手机号码"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item label="密码" prop="password">
                          <el-input
                            show-password
                            v-model="RegisterForm.password"
                            placeholder="请输入长度为6—18的字符串"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item label="专业" prop="specialty">
                          <el-input
                            v-model="RegisterForm.specialty"
                            placeholder="请输入真实专业信息"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item label="年级" prop="grade">
                          <el-select
                            v-model="RegisterForm.grade"
                            clearable
                            placeholder="请选择年级"
                            style="width: 100%"
                          >
                            <el-option
                              v-for="item in gradeList"
                              :key="item.value"
                              :label="item.dictLabel"
                              :value="item.dictValue"
                            >
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item label="学历" prop="education_background">
                          <el-select
                            style="width: 100%"
                            placeholder="请选择真实学历"
                            v-model="RegisterForm.education_background"
                          >
                            <el-option
                              v-for="item in educationBackgroundList"
                              :key="item"
                              :label="item"
                              :value="item"
                            >
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item label="姓名" prop="realname">
                          <el-input
                            v-model="RegisterForm.realname"
                            placeholder="请输入真实姓名信息"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item label="昵称" prop="nickname">
                          <el-input
                            v-model="RegisterForm.nickname"
                            placeholder="请输入唯一昵称信息"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item label="学号" prop="student_num">
                          <el-input
                            v-model="RegisterForm.student_num"
                            placeholder="请输入真实学号信息"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item label="学校" prop="school_name">
                          <el-input
                            v-model="RegisterForm.school_name"
                            placeholder="请输入真实学校信息"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item label="邮箱" prop="email">
                          <el-input
                            v-model="RegisterForm.email"
                            placeholder="请输入合法邮箱"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item label="指导老师" prop="faculty_adviser">
                          <el-input
                            v-model="RegisterForm.faculty_adviser"
                            placeholder="请输入对应指导老师，没有则填‘无’"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-row>
                          <el-col :span="18">
                            <el-form-item label="图片验证码" prop="name">
                              <el-input
                                v-model="RegisterForm.ImgVerification"
                              ></el-input>
                            </el-form-item>
                          </el-col>
                          <el-col :span="6" style="height: 40px">
                            <img
                              style="height: 100%; width: 100%"
                              @click="ChangeiMG"
                              :src="VerificationCodeImgUrl"
                              alt=""
                            />
                          </el-col>
                        </el-row>
                      </el-col>
                      <el-col :span="12">
                        <el-row>
                          <el-col :span="18">
                            <el-form-item
                              label="短信验证码"
                              prop="verification_code"
                            >
                              <el-input
                                v-model="RegisterForm.verification_code"
                                placeholder="请输入短信验证码"
                              ></el-input>
                            </el-form-item>
                          </el-col>
                          <el-col :span="6" style="height: 40px">
                            <el-button
                              @click="GetPhoneVerification"
                              :disabled="!disabledCodeBtn"
                              type="success"
                              >{{ codeText }}</el-button
                            >
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-form>
                  <el-row style="width: 200px; margin: 0px auto">
                    <el-col
                      :span="24"
                      style="text-align: center; margin-top: 20px"
                    >
                      <el-button
                        style="width: 100%"
                        @click="ClickRegister"
                        plain
                        >注&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;册</el-button
                      >
                    </el-col>
                  </el-row>
                  <el-row
                    style="
                      text-align: center;
                      margin-top: 10px;
                      font-size: 14px;
                      color: #606266;
                    "
                  >
                    <el-col>
                      <el-checkbox v-model="checked">同意遵守</el-checkbox><span @click="JumpUserAgreement" style="color: rgb(0, 100, 200)">《用户注册协议》</span>
                      <br />
                      新版《用户协议》已更新，请您及时查看
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  send_graphic,
  phone_verification,
  register,
  //   login,
} from "../api/login";
export default {
  data() {
    return {
      loading: false,
      State: 1,
      disabledCodeBtn: true,
      codeText: "获取验证码",
      checked: false,
      // 登录表单
      loginForm: {
        username: "",
        password: "",
      },
      // 注册表单
      RegisterForm: {},
      // 登录提交验证
      LoginRules: {
        username: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          // { pattern: /^1[34578]d{9}$/, message: "手机号格式错误" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      RegisterRules: {
        username: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          // { pattern: /^1[34578]d{9}$/, message: "手机号格式错误" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        specialty: [{ required: true, message: "请输入专业", trigger: "blur" }],
        grade: [{ required: true, message: "请选择年级", trigger: "change" }],
        education_background: [
          { required: true, message: "请输入学历", trigger: "change" },
        ],
        realname: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        nickname: [{ required: true, message: "请输入昵称", trigger: "blur" }],
        student_num: [
          { required: true, message: "请输入学号", trigger: "blur" },
        ],
        school_name: [
          { required: true, message: "请输入学校", trigger: "blur" },
        ],
        email: [
          { required: true, message: "请输入邮箱", trigger: "blur" },
        //   {
        //     pattern: /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/,
        //     message: "邮箱格式错误",
        //   },
        ],
        faculty_adviser: [
          { required: true, message: "请输入指导老师", trigger: "blur" },
        ],
        verification_code: [
          { required: true, message: "请输入手机验证码", trigger: "blur" },
        ],
      },
      VerificationCodeImgUrl: "", //验证码图片
      phone_verificationFrom: {
        phone_num: "",
        graphic_code: "",
      },
      gradeList: [],
      educationBackgroundList: [
        "高中及以下",
        "大专",
        "本科",
        "硕士研究生",
        "博士研究生",
      ],
    };
  },
  created() {
    // this.Getsend_graphicImg()
    // 为注册状态是请求验证码
    if (this.State == 2) {
      this.Getsend_graphicImg();
    }
    this.getNf();
    // if (location.href.indexOf("#login") == -1) {
    //   location.href = location.href + "#login";
    //   location.reload();
    // }
  },
  mounted() {
    // location.reload()
  },
  watch: {
    // 切换到注册状态是请求验证码
    State(newName) {
      if (newName == 2) {
        this.Getsend_graphicImg();
      }
    },
  },
  methods: {
    getNf() {
      var nfOptionsArray = new Array();
      var years = new Date().getFullYear();
      for (let i = years - 9; i <= years; i++) {
        var anOption = {};
        anOption.dictValue = i + "";
        anOption.dictLabel = i + "";
        nfOptionsArray.push(anOption);
      }
      this.gradeList = nfOptionsArray;
    },
    ChangeState(state) {
      if (state == 1) {
        this.resetForm("RegisterForm");
        this.RegisterForm = {};
      } else {
        this.resetForm("loginForm");
        this.loginForm = {};
      }
      this.State = state;
    },
    // 图形验证码请求
    Getsend_graphicImg() {
      send_graphic().then((res) => {
        this.VerificationCodeImgUrl = "data:image/png;base64," + res;
      });
    },
    // 切换图形验证码
    ChangeiMG() {
      this.Getsend_graphicImg();
    },
	// 忘记密码
	ForgetPasswords(){
		this.$router.push('/ForgetPassword');
	},
	JumpUserAgreement(){
		window.open("https://resource.delist.cn/用户注册协议V3.pdf")
	},
    // 获取手机验证码
    GetPhoneVerification() {
      this.phone_verificationFrom.phone_num = this.RegisterForm.username;
      this.phone_verificationFrom.graphic_code =
        this.RegisterForm.ImgVerification;
      if (!this.phone_verificationFrom.phone_num) {
        this.$message({
          message: "请输入手机号",
          type: "warning",
        });
        return;
      } else if (!this.phone_verificationFrom.graphic_code) {
        this.$message({
          message: "短信验证码不能为空",
          type: "warning",
        });
        return;
      } else {
        this.$store.commit("app/CHANGE_LOADING", true);
        phone_verification(this.phone_verificationFrom)
          .then((res) => {
            if (res.code == 200) {
              this.countDown(60);
              this.$store.commit("app/CHANGE_LOADING", false);
              this.$message({
                type: "success",
                message: "已发送短信",
              });
            } else {
              this.$store.commit("app/CHANGE_LOADING", false);
            }
          })
          .catch(() => {
            this.$store.commit("app/CHANGE_LOADING", false);
          });
      }
    },
    // 倒计时方法
    countDown(time) {
      if (time === 0) {
        this.disabledCodeBtn = true;
        this.codeText = "获取验证码";
        return;
      } else {
        this.disabledCodeBtn = false;
        this.codeText = "重新发送(" + time + ")";
        time--;
      }
      setTimeout(() => {
        this.countDown(time);
      }, 1000);
    },
    // 注册
    ClickRegister() {
      this.$store.commit("app/CHANGE_LOADING", true);
      this.$refs["RegisterForm"].validate((valid) => {
        if (valid) {
			if (!this.checked) {
				this.$message({
				type: "danger",
				message: "请阅读并勾选用户协议",
				});
				this.$store.commit("app/CHANGE_LOADING", false);
				return;
			}
          register({
            register_dict: this.RegisterForm,
            verification_code: this.RegisterForm.verification_code,
          })
            .then((res) => {
              if (res.code == 200) {
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                this.$store.commit("app/CHANGE_LOADING", false);
                this.State = 1;
                this.resetForm("RegisterForm");
                this.RegisterForm = {};
              } else {
                this.$store.commit("app/CHANGE_LOADING", false);
              }
            })
            .catch(() => {
              this.$store.commit("app/CHANGE_LOADING", false);
            });
        } else {
          this.$store.commit("app/CHANGE_LOADING", false);
          return false;
        }
      });
    },
    // 登录
    ClickLogin() {
      if (!this.loginForm.username) {
        this.$message({
          message: "请输入手机号",
          type: "warning",
        });
      } else if (!this.loginForm.password) {
        this.$message({
          message: "请输入密码",
          type: "warning",
        });
      } else {
        this.loading = true;
        this.$store
          .dispatch("Login", this.loginForm)
          .then(() => {
            this.loading = false;
            this.loginForm = {};
            this.$router.push({
              path: this.redirect || "/PersonalInfo",
            });
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  height: auto;
  .login-comp {
    position: relative;
    height: auto;
    padding-top: 40px;
    width: 100%;
    min-width: 992px;
    background-color: rgb(245, 250, 252);
    .login-comp-center {
      margin: 0 auto;
      .spin-nested-loading {
        position: relative;
        .spin-login {
          position: relative;
          .game-list {
            min-height: 716px;
            position: relative;
            .loginForm {
              // position: absolute;
              // top: 50%;
              // left: 50%;
              margin: 0 auto;
              width: 800px;
              min-width: 800px;
              // height: 400px;
              // height: 150px;
              cursor: pointer;
              background-color: rgba(43, 103, 238, 0.1);
              padding: 40px 0;
              .title {
                text-align: center;
                width: 100%;
                height: 66px;
                line-height: 66px;
                font-size: 24px;
                // font-weight: 600;
              }
            }
          }
        }
      }
    }
  }
}
@media (min-width: 1400px) {
  .page-m,
  .comp-m,
  .title-m,
  .about-m,
  .bussiness-m,
  .footer-m,
  .box-m {
    width: 1200px;
  }
}
@media (min-width: 992px) {
  .page-m,
  .comp-m,
  .title-m,
  .about-m,
  .bussiness-m,
  .footer-m,
  .box-m {
    width: 100%;
  }
}
</style>